.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 16px;

  .logo {
    margin: 24px auto;
    width: 300px;
  }

  :global {
    .ant-form {
      > *:last-child {
        width: 100%;
      }
    }

    .logo {
      max-width: 240px;
    }
  }

  .content {
    width: 456px;
    margin: 16px auto;
    padding: 32px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  }
}
