@import 'src/resource/style/variables';

.selectLang {
  display: flex;
  align-items: center;
  align-content: center;

  svg {
    margin-right: 8px;
  }
}

.avatar {
  margin: -4px 8px 0 -8px;
}

.gridGap {
  display: grid;
  grid-gap: 8px;
}

.layout {
  max-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: $bg-color-content;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $header-height;

    .logoSvg {
      width: 100%;
      max-width: 134px;
      height: 32px;
      object-fit: contain;
    }

    &.collapsed {
      .logoSvg {
        height: 28px;
      }
    }
  }

  .menu {
    height: calc(100% - $header-height - $menu-collapse-height);
    overflow: hidden;
  }

  .slider {
    position: relative;
    z-index: 3;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #001529;

    &.absolute {
      position: absolute;
    }

    .btn {
      width: 100%;
      height: $menu-collapse-height;
      line-height: $menu-collapse-height;
      background: #002140;
      text-align: center;
      transition: 0.1s;
      border-radius: 0;

      svg {
        color: #fff;
      }
    }
  }
}

.header {
  height: $header-height;
  line-height: $header-height;
  padding: 0;
  display: flex;
  justify-content: space-between;

  > * {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: 0 16px;

    li, a {
      display: flex;
      align-content: center;
      align-items: center;
    }

    a {
      height: 48px;
    }
  }
}

.wrapper {
  position: relative;
  background-color: $bg-color-content;

  &.tablet {
    margin-left: $menu-collapse-width;
  }

  .scroll {
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - $header-height);
    overflow: auto;

    .content {
      position: relative;
      box-sizing: border-box;
      min-width: calc(100% - 16px * 2);
      margin: 16px;
      background: #ffffff;
      border-radius: 8px;

      &.transparent {
        background: transparent;
      }

      &.minWidth {
        min-width: 920px;
      }
    }
  }
}
