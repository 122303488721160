@import "src/resource/style/variables";

.input {
  width: 60px;
  height: 60px;

  input {
    width: 60px;
    height: 60px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $primary-color;
  }
}
