.title {
  display: block;
  margin: 0 0 20px 0 !important;
  font-size: 16px;
}

.secondary {
  letter-spacing: -0.5px;
}

.password {
  margin-bottom: 19px;
}

.btn {
  margin-top: 12px;
}

.email {
  margin-top: 32px;
  margin-bottom: 40px;
}

.logo {
  display: block;
  margin: 24px auto;
  width: 300px;
  object-fit: contain;
}
